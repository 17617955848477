enum ActionTypes {
  next = 'next',
  previous = 'previous',
  changeCalendarView = 'changeCalendarView',
  selectMonth = 'selectMonth',
  selectYear = 'selectYear',
  selectDecade = 'selectDecade',
  selectDay = 'selectDay',
  selectHour = 'selectHour',
  selectMinute = 'selectMinute',
  selectSecond = 'selectSecond',
  incrementHours = 'incrementHours',
  incrementMinutes = 'incrementMinutes',
  incrementSeconds = 'incrementSeconds',
  decrementHours = 'decrementHours',
  decrementMinutes = 'decrementMinutes',
  decrementSeconds = 'decrementSeconds',
  toggleMeridiem = 'toggleMeridiem',
  togglePicker = 'togglePicker',
  showClock = 'showClock',
  showHours = 'showHours',
  showMinutes = 'showMinutes',
  showSeconds = 'showSeconds',
  clear = 'clear',
  close = 'close',
  today = 'today',
}

export default ActionTypes;
